import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Uris } from './Uris';
import { Observable, map } from 'rxjs';
import { CACHING_ENABLED } from '../../core/interceptors/headers.handler.interceptor';
import { User } from '../../core/interfaces/interfaces.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public http: HttpClient) {}

  login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Observable<ResponseUser> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + `${btoa(`${email}:${password}`)})`,
    });
    return this.http
      .post<ResponseUser>(
        Uris.AUTH_LOGIN,
        {},
        {
          headers: headers,
          context: new HttpContext().set(CACHING_ENABLED, false),
        },
      )
      .pipe(
        map((resp) => {
          localStorage.setItem('token', resp.token);
          localStorage.setItem('USER', JSON.stringify(resp.user));
          return resp;
        }),
      );
  }
  logout() {
    return this.http.post(Uris.AUTH_LOGOUT, {}).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }

  getFileTxt(file: string): Observable<string> {
    return this.http
      .get(`assets/txt/${file}.txt`, {
        responseType: 'text',
        context: new HttpContext().set(CACHING_ENABLED, false),
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  passwordSendRequest(body: { email: string }): Observable<{ email: string }> {
    return this.http
      .post<{ email: string }>(`${Uris.PASSWORD_RESET}/send_request/`, body, {
        context: new HttpContext().set(CACHING_ENABLED, false),
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  passwordConfirmRequest(body: {
    token: string;
    new_password: string;
  }): Observable<{ token: string; new_password: string }> {
    return this.http
      .post<{ token: string; new_password: string }>(
        `${Uris.PASSWORD_RESET}/confirm_request/`,
        body,
        {
          context: new HttpContext().set(CACHING_ENABLED, false),
        },
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
}

export interface ResponseUser {
  expiry: string;
  token: string;
  user: User;
}
